import { PagingMixin } from '../../../mixins/paging';
import { mixins } from 'vue-class-component';
import { productionOrderProductService, prepressReceiptService, customerService, minioService } from '@/api';
import { Query, SortOptions } from '@/api/base';
import { OsQueryPlan, OsTable } from '@/components';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { OsTableOption, OsTableColumn, RowOperation } from '@/components/os-table/os-table';
import { dateFormat } from '@/filters';
import {
  ProductionProductStatusEnum,
  ProcessStatusEnum,
  ChargeUnitEnum,
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  ProjectItemTypeEnum,
  PlatformPrepressStatusEnum,
  PageQueryPlanEnum
} from '@/resource/enum';
import { ProductionOrderProductResource } from '@/resource/model';
import {
  messageError,
  downloadFileByBlob,
  translation,
  showWarningConfirm,
  isNullOrUndefinedForBaseType,
  getFileExtension,
  downloadFileByPremoteLink,
  messageErrors,
  debounce
} from '@/utils';
import { Component, Watch } from 'vue-property-decorator';
import { ReceiptDetailDialog, ProductBrokenDetailDialog, AddProductBrokenDialog } from '@/views/dialogs';
import { Message } from 'element-ui';
import { CustomColumnMixin } from '@/mixins/custom-column';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
@Component({
  components: {
    OsQueryPlan,
    ReceiptDetailDialog,
    ProductBrokenDetailDialog,
    AddProductBrokenDialog,
    ElImageViewer
  }
})
export default class PrepressReceiptList extends mixins(PagingMixin, CustomColumnMixin) {
  /**
   * 表格引用
   */
  public $table!: OsTable;

  /**
   * 单据详情窗口显示标识
   */
  public detailDialogVisible = false;

  /**
   * 报损记录窗口显示标识
   */
  public brokenDetailDialogVisible = false;

  /**
   * 新建报损窗口显示控制
   */
  public brokenReportDialogVisible = false;

  /**
   * 接单弹窗显示控制
   */
  public takeReceiptsDialogVisible = false;

  /**
   * 当前查看行数据
   */
  public viewRow: ProductionOrderProductResource | null = null;
  /**
   * 当前查看行数据ID
   */
  public viewRowId: number | null = null;
  /**
   * 超时单据总数
   */
  public timeoutCount = 0;
  /**
   * 即将超时单据总数
   */
  public timeoutComingUpCount = 0;

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<ProductionOrderProductResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true,
    defaultSort: { order: 'ascending', prop: 'requiredDeliveryTime' }
  };

  /**
   * 查询条件UI配置
   */
  public queryFormOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'receipt.inputKeyWord'
      }
    },
    {
      type: 'Select',
      field: 'processStatusList',
      label: 'receipt.status',
      option: {
        placeholder: 'common.selectStatus',
        multiple: true
      },
      optionData: productionOrderProductService.getProcessStatusSelectableList
    },
    {
      type: 'Select',
      field: 'platformPrepressStatusList',
      label: 'receipt.platformPrepressStatus',
      option: {
        placeholder: 'common.selectStatus',
        multiple: true
      },
      optionData: productionOrderProductService.getPlatformStatus
    },
    // {
    //   type: 'Select',
    //   field: 'urgentOrderFlag',
    //   label: 'order.urgentOrder',
    //   option: {
    //     placeholder: 'common.select'
    //   },
    //   optionData: (): NormalSelectOptions => {
    //     return [
    //       {
    //         label: translation('common.yes'),
    //         value: 1
    //       },
    //       {
    //         label: translation('common.no'),
    //         value: 0
    //       }
    //     ];
    //   }
    // },
    {
      type: 'DateRangePicker',
      field: 'deliveryDate',
      label: 'receipt.deliveryDate',
      option: {
        startPlaceholder: 'common.startPlaceholder',
        endPlaceholder: 'common.endPlaceholder'
      }
    },
    {
      type: 'Select',
      field: 'itemType',
      label: 'orderProduct.itemType',
      option: {
        placeholder: 'receipt.selectItemType'
      },
      optionData: productionOrderProductService.getItemType
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'receipt.customer',
      option: {
        placeholder: 'receipt.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'orderProduct.productName',
      option: {
        placeholder: 'receipt.inputProductName'
      }
    },
    // {
    //   type: 'Input',
    //   field: 'prepressPicture',
    //   label: 'orderProduct.prepressPicture',
    //   option: {
    //     placeholder: 'receipt.inputPrepressPicture'
    //   }
    // },
    // {
    //   type: 'Input',
    //   field: 'createUserName',
    //   label: 'common.createUserName',
    //   option: {
    //     placeholder: 'common.inputCreateUserName'
    //   }
    // },
    {
      type: 'DateRangePicker',
      field: 'createDate',
      label: 'receipt.createDate',
      option: {
        startPlaceholder: 'common.startPlaceholder',
        endPlaceholder: 'common.endPlaceholder',
        placeholder: 'common.inputCreateDate',
        pickerOptions: {
          disabledDate(callbackDateStr: string): Boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();

            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * 接单按钮loading控制
   */
  public takeLoading = false;

  /**
   * 查询表单对象
   */
  public queryForm: Query<{
    keywords: string;
    processStatusList: Array<number>;
    companyId: Number | null;
    customerId: Number;
    itemType: Number;
    createUserName: string;
    createDate: Array<string>;
    deliveryDate: Array<string>;
    platformPrepressStatusList: Array<number>;
    type: number;
    delayFlag: number;
    startDate: string;
    endDate: string;
    platformProductName: string;
    startDeliveryDate: string;
    endDeliveryDate: string;
    prepressPicture: string;
    urgentOrderFlag: number;
  }> = {
    companyId: undefined,
    createDate: [],
    createUserName: '',
    customerId: undefined,
    delayFlag: undefined,
    deliveryDate: [],
    endDate: undefined,
    endDeliveryDate: undefined,
    itemType: undefined,
    keywords: '',
    platformPrepressStatusList: [],
    platformProductName: '',
    prepressPicture: '',
    startDate: undefined,
    startDeliveryDate: undefined,
    processStatusList: [],
    type: 1,
    urgentOrderFlag: undefined
  };
  public defaultQueryForm: Query<{
    keywords: string;
    processStatusList: Array<number>;
    companyId: Number | null;
    customerId: Number;
    itemType: Number;
    createUserName: string;
    createDate: Array<string>;
    deliveryDate: Array<string>;
    platformPrepressStatusList: Array<number>;
    type: number;
    delayFlag: number;
    startDate: string;
    endDate: string;
    platformProductName: string;
    startDeliveryDate: string;
    endDeliveryDate: string;
    prepressPicture: string;
    urgentOrderFlag: number;
  }> = {
    companyId: undefined,
    createDate: [],
    createUserName: '',
    customerId: undefined,
    delayFlag: undefined,
    deliveryDate: [],
    endDate: undefined,
    endDeliveryDate: undefined,
    itemType: undefined,
    keywords: '',
    platformPrepressStatusList: [],
    platformProductName: '',
    prepressPicture: '',
    startDate: undefined,
    startDeliveryDate: undefined,
    processStatusList: [],
    type: 1,
    urgentOrderFlag: undefined
  };

  /**
   * 表格操作栏
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.take',
      operationType: 'take',
      icon: 'el-icon-check',
      permissionCode: 'order:item:prepress:batchTake',
      disabled: true,
      loading: false,
      handleClick: (): void => {
        this.handleTake();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.finish',
      operationType: 'finish',
      icon: 'el-icon-finished',
      permissionCode: 'order:item:prepress:batchFinish',
      disabled: true,
      loading: false,
      handleClick: (): void => {
        // 单据完成
        this.finishReceipts();
      }
    },
    {
      plain: true,
      slot: 'start',
      label: 'button.exportDetail',
      operationType: 'export',
      icon: 'el-icon-download',
      permissionCode: 'order:item:all:export',
      loading: false,
      handleClick: (): void => {
        this.export();
      }
    }
  ];

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductionOrderProductResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'orderCode',
      label: 'receipt.orderCode',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'receipt.itemCode',
      minWidth: '200px',
      showOverflowTooltip: true,
      sortable: 'custom',
      fixed: true
    },
    {
      prop: 'itemType',
      label: 'orderProduct.itemType',
      minWidth: '120px',
      showOverflowTooltip: true,
      formatter: (rowData: object): string => {
        return translation(
          `projectItemType.${ProjectItemTypeEnum[(rowData as ProductionOrderProductResource).itemType]}`
        );
      }
    },
    {
      prop: 'prepressStatus',
      label: 'receipt.platformPrepressStatus',
      minWidth: '150px',
      formatter: (row: object): string => {
        if (!(row as ProductionOrderProductResource).makeUserName) {
          return translation(
            'platformPrepressStatus.' +
              PlatformPrepressStatusEnum[(row as ProductionOrderProductResource).prepressStatus]
          );
        }

        if (!PlatformPrepressStatusEnum[(row as ProductionOrderProductResource).prepressStatus]) {
          return `[${(row as ProductionOrderProductResource).makeUserName}]${translation(
            'common.unKnownStatus'
          )}`;
        }

        return `[${(row as ProductionOrderProductResource).makeUserName}]${translation(
          'platformPrepressStatus.' +
            PlatformPrepressStatusEnum[(row as ProductionOrderProductResource).prepressStatus]
        )}`;
      }
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'orderProduct.requiredDeliveryTime',
      minWidth: '180px',
      sortable: 'custom',
      showOverflowTooltip: true
    },
    {
      prop: 'customerName',
      label: 'orderProduct.customerName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'projectName',
      label: 'orderProduct.projectName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'orderProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'orderProduct.productName',
      minWidth: '400px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'orderProduct.backendCrafts',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'orderProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'totalArea',
      label: 'orderProduct.totalArea',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleWidth',
      label: 'orderProduct.visibleSize',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishWidth',
      label: 'orderProduct.completeSize',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'screenArea',
      label: 'receipt.screenArea',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    // {
    //   prop: 'screenRate',
    //   label: 'receipt.screenRate',
    //   minWidth: '100px',
    //   showOverflowTooltip: true
    // },
    {
      prop: 'prepressCrafts',
      label: 'orderProduct.prepressCrafts',
      minWidth: '120px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if ((row as ProductionOrderProductResource).itemType === ProjectItemTypeEnum.changeColor) {
          return translation(
            `prepressChangeColorCrafts.${
              PrepressChangeColorCraftsEnum[(row as ProductionOrderProductResource).prepressCrafts]
            }`
          );
        }
        return translation(
          `prepressLayoutCrafts.${
            PrepressLayoutCraftsEnum[(row as ProductionOrderProductResource).prepressCrafts]
          }`
        );
      }
    },
    {
      prop: 'prepressConfirmFile',
      label: 'receipt.thumbnails',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressCompleteFile',
      label: 'receipt.productionFile',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressPicture',
      label: 'orderProduct.prepressPicture',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'orderProduct.prepressDescription',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'orderProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'orderProduct.requiredArriveTime',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'common.status',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'orderTakingUserName',
      label: 'orderProduct.takeUserName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'orderTakingTime',
      label: 'orderProduct.takeTime',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'orderProduct.createUserName',
      minWidth: '120',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionFlag',
      label: 'orderProduct.isConsumeRemake',
      minWidth: '120px',
      showOverflowTooltip: true,
      formatter: (row: Object): string => {
        if (isNullOrUndefinedForBaseType((row as ProductionOrderProductResource).attritionFlag)) {
          return '--';
        }
        return translation(
          `common.${(row as ProductionOrderProductResource).attritionFlag === 0 ? 'no' : 'yes'}`
        );
      }
    },
    {
      prop: 'dutyReason',
      label: 'brokenLog.reason',
      minWidth: '150',
      showOverflowTooltip: true
    },
    {
      prop: 'attritionItemCode',
      label: 'brokenLog.brokenItem',
      minWidth: '150',
      showOverflowTooltip: true
    }
  ];

  /**
   * 表格行操作配置
   */
  public rowOperationOption: RowOperation<ProductionOrderProductResource> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'detail',
        type: 'text',
        label: 'orderProduct.brokenLog',
        icon: 'el-icon-tickets',
        permissionCode: 'production:report:listPage',
        handleClick: (row: ProductionOrderProductResource): void => {
          // 查看报损记录
          this.openBrokenLogDialog(row);
        }
      },
      {
        operationType: 'add',
        type: 'text',
        label: 'receipt.brokenReport',
        icon: 'el-icon-plus',
        permissionCode: 'order:item:prepress:report',
        handleClick: (row: ProductionOrderProductResource): void => {
          // 打开报损窗口
          this.openBrokenReportDialog(row);
        }
      }
    ]
  };
  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.prepressReceiptList;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  /**
   * 展示确稿预览文件
   */
  public showThumbnails = false;

  /**
   * 预览图片列表当前索引
   */
  public initialIndex = 0;

  /**
   * 要预览的图片列表
   */
  public previewImgList: Array<string> = [];

  /**
   * 选中数据缓存对象
   */
  private selectedRows: Array<ProductionOrderProductResource> = [];

  private sortOptions: SortOptions<ProductionOrderProductResource> = this.tableOption.defaultSort!;

  public get area(): string {
    return this.selectedRows.reduce((res, curr) => res + curr.totalArea, 0).toFixed(3);
  }

  public created(): void {
    this.initCustomSelectableList();
    this.initColumns(this.tableColumnOptions, 'prepressReceipt');
  }

  public mounted(): void {
    this.$table = this.$refs.tableRef as OsTable;
    this.queryClick();
    this.loadTimeoutCount();
  }
  public activated(): void {
    // this.queryClick();
  }

  public handleTimeoutClick(): void {
    // 已超时
    this.queryForm.delayFlag = 1;
    this.loadData();
  }

  public handleTimeoutComingUpClick(): void {
    // 已超时
    this.queryForm.delayFlag = 2;
    this.loadData();
  }

  /**
   * 根据状态获取i18n key
   * @param status 状态值
   * @returns
   */
  public getProcessStatusI18Key(status: ProcessStatusEnum): string {
    return productionOrderProductService.getProcessStatusI18Key(status);
  }

  /**
   * 根据状态获取class
   * @param status  状态值
   * @returns
   */
  public getStatusClass(status: ProductionProductStatusEnum): string {
    return productionOrderProductService.getStatusClass(status);
  }

  /**
   * 获取计量单位国际化key
   * @param unit 计量单位
   * @returns
   */
  public getChargeUnitI18nKey(priceUnit: ChargeUnitEnum): string {
    return 'chargeUnit.' + ChargeUnitEnum[priceUnit];
  }

  /**
   * 打开单据详情
   * @param rowData 单据数据对象
   */
  public openProductDetailDialog(rowData: ProductionOrderProductResource): void {
    this.viewRow = rowData;
    this.detailDialogVisible = true;
  }

  /**
   * 打开单据报损记录页面
   * @param rowData 单据数据对象
   */
  public openBrokenLogDialog(rowData: ProductionOrderProductResource): void {
    this.viewRowId = rowData.id;
    this.brokenDetailDialogVisible = true;
  }

  /**
   * 新建报损记录
   * @param rowData 单据数据对象
   */
  public openBrokenReportDialog(rowData: ProductionOrderProductResource): void {
    this.viewRow = rowData;
    this.brokenReportDialogVisible = true;
  }

  /**
   * 分页切换回调
   */
  public pagingData(): void {
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<ProductionOrderProductResource>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<ProductionOrderProductResource>): void {
    this.selectedRows = selectedData;
  }

  public isShowWarnning(rowData: ProductionOrderProductResource): boolean {
    return !!rowData.whetherEdited;
  }

  /**
   * 重新加载数据
   */
  public reloadData(): void {
    this.paging.currentPage = 1;
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.$table.clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }
  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  /**
   * 接单
   */
  public handleTake(): void {
    const allowPrepressStatus = [PlatformPrepressStatusEnum.completed, PlatformPrepressStatusEnum.notMade];
    const pengingTakeReceipts = this.selectedRows.filter(
      item =>
        item.processStatus === ProcessStatusEnum.waiting &&
        (allowPrepressStatus.includes(item.prepressStatus) ||
          item.itemType === ProjectItemTypeEnum.changeColor)
    );

    if (pengingTakeReceipts.length <= 0) {
      Message.warning(translation('receipt.selectReceipts'));
      return;
    }

    showWarningConfirm(translation('receipt.takeReceiptsTip'))
      .then(async () => {
        const option = this.tableToolbarOptions.find(option => option.operationType === 'take');
        option!.loading = true;
        try {
          const idList = pengingTakeReceipts.map(item => item.id);

          await prepressReceiptService.batchTake(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error as any);
        } finally {
          option!.loading = false;
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  public async thumbnailsPreview(fileName: string): Promise<void> {
    try {
      const url = fileName;
      if (getFileExtension(fileName) === 'pdf') {
        const newWindow = window.open();
        newWindow?.document.write(
          `<iframe width="100%" height="100%" src="${url}" frameborder="0" allowfullscreen></iframe>`
        );
        return;
      }
      
      this.initialIndex = this.previewImgList.indexOf(fileName);
      this.showThumbnails = true;
    } catch (error) {
      messageError(error as any);
    }
  }

  public async downloadProductionFile(row: ProductionOrderProductResource): Promise<void> {
    try {
      await minioService.init();
      // const path = await minioService.getDownLoadPath(row.prepressCompleteFile);
      downloadFileByPremoteLink(row.prepressCompleteFile);
    } catch (error) {
      messageError(error as any);
    }
  }

  /**
   * 判断文件是否可预览
   * @param filePath 文件路径
   * @returns
   */
  private canPrepressConfirmFilePreview(filePath: string): boolean {
    const notAllowPreviewExtNameRegex = /^pdf/;
    const fileExtName = filePath.substring(filePath.lastIndexOf('.') + 1);
    if (notAllowPreviewExtNameRegex.test(fileExtName)) {
      return false;
    }
    return true;
  }
  private handleOpenWindowPreview(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }

  /**
   * 监视表格行选中数据变化
   * @param value 变化数据
   */
  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<ProductionOrderProductResource>): void {
    const allowedOpationArray = ['take', 'finish'];
    this.tableToolbarOptions.forEach(button => {
      if (allowedOpationArray.indexOf(button.operationType) !== -1) {
        button.disabled = value.length === 0;
      }
    });
    this.tableToolbarOptions = [...this.tableToolbarOptions];
  }

  @Watch('queryForm.createDate')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startDate = undefined;
      this.queryForm.endDate = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startDate = dateFormat(value[0], 'YYYY-MM-DD');
      this.queryForm.endDate = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.deliveryDate')
  private handleDeliveryDateChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.startDeliveryDate = undefined;
      this.queryForm.endDeliveryDate = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.startDeliveryDate = dateFormat(value[0], 'YYYY-MM-DD');
      this.queryForm.endDeliveryDate = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  /**
   * 加载数据
   */
  private loadData(): void {
    this.$table.openLoading();
    prepressReceiptService
      .getList(this.queryForm as any, this.paging, this.sortOptions)
      .then(res => {
        this.tableOption.data = res.data.map(x => {
          x.screenArea = ((x.finishWidth / 1000) * (x.finishHeight / 1000)).toFixed(3);
          return x;
        });
        this.totalData = res.total;

        this.previewImgList = [];
        this.previewImgList = this.tableOption.data.filter(item => item.prepressConfirmFile !== null)
        .map(x => x.prepressConfirmFile);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.$table.closeLoading();
      });
  }

  /**
   * 导出明细
   */
  private async export(): Promise<void> {
    const option = this.tableToolbarOptions.find(option => option.operationType === 'export');
    option!.loading = true;

    const idList = this.selectedRows.map(row => row.id);

    let exportCondition = {
      ...this.queryForm
    } as any;

    if (idList.length > 0) {
      exportCondition = { idList };
    }

    try {
      const blob = await prepressReceiptService.export(exportCondition);

      downloadFileByBlob(
        `${translation('receipt.export')}-${dateFormat(new Date(), 'YYYYMMDDHHmmss')}.xlsx`,
        blob
      );
    } catch (error) {
      messageError(error as any);
    } finally {
      option!.loading = false;
    }
  }

  /**
   * 查询超时/即将超时单据数量
   */
  private loadTimeoutCount(): void {
    prepressReceiptService
      .getTimeoutCount()
      .then(res => {
        this.timeoutCount = res.overtimeCount;
        this.timeoutComingUpCount = res.willOvertimeCount;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.$table.closeLoading();
      });
  }

  /**
   * 完成单据
   */
  private finishReceipts(): void {
    const pengingFinishReceipts = this.selectedRows.filter(
      item => item.processStatus === ProcessStatusEnum.processing
    );

    if (pengingFinishReceipts.length <= 0) {
      Message.warning(translation('receipt.selectProcessingReceipts'));
      return;
    }

    showWarningConfirm(translation('receipt.finishReceiptsTip'))
      .then(async () => {
        const option = this.tableToolbarOptions.find(option => option.operationType === 'finish');
        option!.loading = true;
        try {
          const idList = pengingFinishReceipts.map(item => item.id);
          await prepressReceiptService.batchFinish(idList);
          this.reloadData();
          Message.success(translation('operationRes.operationSuccess'));
        } catch (error) {
          messageErrors(error as any);
        } finally {
          option!.loading = false;
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.operationCanceled'));
      });
  }

  /**
   * 初始化当前用户可选客户列表
   */
  private async initCustomSelectableList(): Promise<void> {
    const list = await customerService.getAllUsingCustomer();

    const selectableList: Array<any> = [];
    list.forEach((item: any) => {
      selectableList.push({
        label: item.customerName,
        value: item.customerId
      });
    });

    const option = this.queryFormOption.find(item => item.field === 'customerId');

    option!.optionData = selectableList;
  }
}
